html, body, * {
    font-size: calc(10px + 2vmin);
    box-sizing: border-box;
}

body {
    overscroll-behavior-y: contain;
}

.no-display {
    display: none;
}

.info-box {
    width: 100%;
    font-size: 0.8em;
    padding: 1em;
    
    background: #2c4181;
    color: white;
    text-align: center;
    line-height: 1.4;
    align-self: start;
    position: relative;
    z-index: 998;
}
.info-box * {
    font-size: 1em;
}
.info-box >hr {
    border-style: solid;
    border-color: #5b6b9d;
}

.float-right {
    float: right;
}

.nopad-btn-right {
    width: auto;
    min-width: auto !important;
    height: auto;
    padding: 0 !important;
    margin: 0.2em 1em 0 0 !important;
}

p.small {
    font-size: 0.8em;
    text-align: justify;
}

.app-header {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.5em;
    top: 0;
    left: 0;
    right: 0;
    height: 2.7em;
    padding: 0 0.7em 0 0.7em;
    font-size: 1.3em;
    background-color: #152c73;
    color: white;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    z-index: 999;
}

.app-header-item {
    display: block;
}

.app-header-props {
    display: block;
    margin-left: auto;
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
}

.prop-box {
    display: block;
    width: 2.3em;
    text-align: center;
}

.app-header img {
    height: 2em;
    width: auto;
}

.app-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #2c4181;
    color: white;
    display: flex;
    justify-content: center;
    padding: 1em 0;
    height: 3.8em;
    justify-content: space-evenly;
    z-index: 999;
}

.app-footer button {
    color: white;
}

.app-footer .Mui-disabled {
    color: #122767 !important;
}

.app {
    background-color: #f0f0f0;/*#282c34;*/
    height: 100vh;
    max-height: -webkit-fill-available;
    color: #282c34;
    padding-top: 3.5em;
    padding-bottom: 3.8em;
    display: grid;
}

.app-nocenter {
    background-color: #f0f0f0;/*#282c34;*/
    height: 100vh;
    max-height: -webkit-fill-available;
    color: #282c34;
    padding-top: 3.5em;
    overflow: scroll;
}

.app-body {
    display: flex;
    flex-direction: column;
    justify-items: center;
    flex-flow: column;
}

.full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.code-scanner {
    background: #d0d4e3;
    padding: 1em;
    margin-top: 1em;
    border-radius: 0.6em;
    width: calc(300px + 2em);
}

.code-scanner-inner {
    width: 250px;
    height: 250px;
    margin: auto;
}

.text-green {
    color: #66AA66 !important;
}

.text-red {
    color: #AA6666 !important;
}

.text-red-brighter {
    color: #FF6666 !important;
}

.text-blue {
    color: #152c73 !important;
}

.or-0-deg {
    transform: rotate(0deg);
}

.or-90-deg {
    transform: rotate(270deg);
}

.or-180-deg {
    transform: rotate(180deg);
}

.or-270-deg {
    transform: rotate(90deg);
}

.video-capture-container {
    display: block;
    width: auto;
    height: auto;
    position: relative;
}

.code-scanner-inner video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.bg-strip {
    background-color: #152c73;
}

.rel {
    position: relative;
}

.camera-selecter {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;

    width: 2em;
    height: 2em;
    background-color: #a1aac7;
    border: none;
    border-radius: 6px;
}

.camera-info {
    position: absolute;
    top: calc(5px + 2em + 5px);
    right: 5px;
    padding: 0;
    width: 2em;
    height: 2em;
    text-align: center;

    background-color: #a1aac7;
    border: none;
    border-radius: 6px;
    padding-top: 7px;
}

.img-container {
    width: 100%;
    height: 100%;
    padding-bottom: 3.8em;
    overflow-y: auto;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: start;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
}

.photo-video {
    max-width: 100%;
}

.img-preview-parent {
    width: 45%;
    margin: 0.8em auto;
    flex: 1 0 40%;
    flex-grow: 0;
    position: relative;
}

.img-title {
    font-weight: bold;
    font-size: 1em;
    padding: 0.5em 0;
}

.img-delete, .img-delete * {
    color: red;
}
.img-delete {
    position: absolute !important;
    right: 0;
    z-index: 2;
    top: 0.5em;
}

.img-pending {
    position: absolute !important;
    right: 0;
    left: 0;
    text-align: center;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);
}

.img-preview {
    max-width: 100%;
    max-height: 80vh;
    margin: 0 auto 1em auto;
    position: relative;
    z-index: 1;
    display: block;
    border: 1px solid #5b6b9d;
}

.progress-container {
    padding: 0.5em 0;
}

.left-text {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 0.9em;
    padding: 0.4em;
}

.right-text {
    display: block;
    float: right;
}

.padded-box {
    padding: 0.5em;
}

.padded-box>* {
    margin-bottom: 0.5em;
}

.login-credentials {
    margin-top: 1em;
    background: #d0d4e3;
    padding: 1em;
    border-radius: 0.6em;
    width: calc(300px + 2em);
    height: 4em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    font-size: 0.8em;
}

.build-info {
    padding-top: 1.5em;
    font-size: 8pt;
    color: #cccccc;
    text-align: center;
}

.cred-container {
    display: table;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
}

.cred-left {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.6em;
    padding: 0 0.75em 0 0.2em;
    height: 100%;
}

.cred-right {
    display: table-cell;
    height: 100%;
    text-align: left;
    vertical-align: middle;
}

.cred-right-end {
    display: table-cell;
    height: 100%;
    margin-left: auto;
    vertical-align: middle;
}

.cred-cont-bottommargin {
    margin-bottom: 0.5em;
}

.align-center {
    align-self: center;
}

.white {
    color: white !important;
}

table {
    text-align: left;
}

table th {
    padding-right: 0.5em;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes light-blinker {
  50% {
    opacity: 0.5;
  }
}

@keyframes pulsate-pos-down {
  50% {
    transform: translateY(20px);
  }
}

.pulsatingDown {
    transform: translateY(0);
    animation: pulsate-pos-down 1s ease-in-out infinite;
}

.disconnected {
    color: red;
    opacity: 1;
    animation: blinker 1s linear infinite;
}

.pending {
    color: #a1aac7;
    opacity: 1;
    animation: light-blinker 1s linear infinite;
}

.centered {
    display: block;
    text-align: center;
    margin: 0;
}

.centered-block {
    display: block;
    margin: auto;
}

.right-align {
    text-align: right;
}

.padded-right {
    margin-right: 2em !important;
}

.smallText {
    font-size: 0.7em;
}

.smallText * {
    font-size: 1em;
}

.dummy-placeholder {
    font-weight: 300;
    position: fixed;
    text-align: center;
    padding: 2em;
    color: #cccccc;
    line-height: 1.5;
    top: 50%;
    left: 0;
    right: 0;
}

.position-selector {
    background: #d0d4e3;
    padding: 1em;
    margin-top: 1em;
    border-radius: 0.6em;
    width: calc(300px + 2em);
}

.position-selector select {
    width: 100%;
    padding: 0.35em;
}
